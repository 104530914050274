<template>
	<standard-container title="utenti & gruppi" description="" admin>
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabUsers">
					Utenti
				</v-tab>
				<v-tab key="tabGroups">
					Gruppi
				</v-tab>
				<v-tab key="tabUsersStatus">
					Utenti connessi
				</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-btn v-if="currentTab===0 && fabVisible" fab color="warning" top right absolute title="Aggiungi utente" @click="aggiungiUtente">
					<v-icon>mdi-plus</v-icon>
				</v-btn>						
				<v-btn v-if="currentTab===1 && fabVisible" fab color="warning" top right absolute title="Aggiungi gruppo" @click="aggiungiGruppo">
					<v-icon>mdi-plus</v-icon>
				</v-btn>						
				<v-tabs-items v-model="currentTab">
					<!-- tabUsers -->
					<v-tab-item key="tabUsers">
						<v-alert type="info" dense outlined text>
							Lavori in corso... <br>
							Questi sono gli utenti per l'azienda: <strong>{{ currentUser.AziendaCorrente.Nome }}</strong><br>
							Attenzione che gli utenti amministratori sono trasversali rispetto all'azienda!<br>
							Per questo motivo, attualmente, gli amministratori non possono più essere collegati a dei gruppi.<br>
							Notare che gli username devono essere univoci indipendentemente dall'azienda!
						</v-alert>
						<v-row>
							<v-col cols="12">
								<div class="d-block d-md-flex align-baseline">
									<v-text-field ref="searchField" v-model="search" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" autofocus prepend-inner-icon="mdi-magnify" label="Ricerca libera" hide-details clear-icon="mdi-close-circle" clearable />
									<v-switch v-model="visualizzaSospesi" inset label="Visualizza sospesi" color="warning" class="ml-0 ml-md-10" />
								</div>
							</v-col>
							<v-col cols="12">
								<v-data-table 
									:loading="loading"
									:items="usersFiltered" 
									:headers="usersHeaders"
									:sort-by="['FullName']" 
									:sort-desc="[false]"
									item-key="Id"
									hide-default-footer
									disable-pagination
									:search="search"
									show-expand
									:single-expand="false"
									@click:row="editUser"
								>
									<template v-slot:item.Id="{ item }">
										<v-avatar :size="35" :class="item.Stato===0 ? 'opaque' : ''">
											<img v-if="item.Immagine" alt="Avatar" :src="imagesPathPerson + item.Immagine + '?width=70&height=70&mode=crop&anchor=top'">
											<avatar v-else :username="item.FullName" :size="35" />
										</v-avatar>
									</template>
									<template v-slot:item.FullName="{ item }">
										<div :class="item.Stato===0 ? 'opaque' : ''">
											<standard-label cssclass="body-1">
												{{ item.FullName }}
											</standard-label>
											<standard-label cssclass="caption opaque">
												{{ item.Email }}
											</standard-label>
										</div>
									</template>
									<template v-slot:item.Stato="{ item }">
										<v-chip v-if="item.Stato===0" x-small color="warning">
											suspended
										</v-chip>
									</template>
									<template v-slot:item.Tipo="{ item }">
										<div :class="item.Stato===0 ? 'opaque' : ''">
											<v-chip v-if="item.Tipo===99" x-small color="error">☠️ super admin ☠️</v-chip>
											<v-chip v-else-if="item.Tipo===10" x-small color="warning">training manager</v-chip>
											<v-chip v-else-if="item.Tipo===2" x-small color="error">administrator</v-chip>
											<v-chip v-else-if="item.Tipo===1" x-small color="success">super user</v-chip>
											<v-chip v-else-if="item.Tipo===0" x-small color="info">user</v-chip>
										</div>
									</template>
									<template v-slot:item.GruppiAppartenenza="{ item }">
										<div :class="item.Stato===0 ? 'opaque' : ''">
											<span v-for="(gruppo, index) in item.GruppiAppartenenza" :key="index" cssclass="caption"><span v-if="index===1"><br></span><span v-if="index<2" class="mr-1">{{ gruppo }}</span></span>
											<strong v-if="item.GruppiAppartenenza && item.GruppiAppartenenza.length>2">...</strong>
										</div>
									</template>
									<template v-slot:item.Email="{ item }">
										<div :class="item.Stato===0 ? 'opaque' : ''">
											<div v-if="!item.IsCurrentUser">
												<v-btn right x-small color="warning" icon title="cancella utente" @click.stop="eliminaUtente(item)">
													<v-icon>mdi-delete</v-icon>
												</v-btn>
												<v-btn right x-small color="info" icon title="resetta password" @click.stop="resetPasswordUtente(item)">
													<v-icon>mdi-shield-refresh</v-icon>
												</v-btn>
											</div>
										</div>
									</template>
									<template v-slot:expanded-item="{ headers, item }">
										<td :colspan="headers.length" class="tdExtended">
											<div class="mt-2 mb-5 d-flex flex-column flex-lg-row justify-lg-space-between">
												<v-card style="background-color:rgba(255,0,0,0.025)" class="mt-1">
													<v-card-title>Gruppi per l'utente "{{ item.FullName }}"</v-card-title>
													<v-card-text>
														<table class="tblGruppi">
															<tbody v-for="gruppo in item.GruppiAppartenenza" :key="gruppo">
																<tr>
																	<td class="body-2">
																		{{ gruppo }}
																	</td>
																	<td class="caption">
																		{{ decodificaDescrizioneGruppo(gruppo) }}
																	</td>
																</tr>
															</tbody>
														</table>
													</v-card-text>
												</v-card>
												<v-card style="background-color:rgba(0,255,0,0.025)" class="mt-1">
													<v-card-title>Dettagli utente "{{ item.FullName }}"</v-card-title>
													<v-card-text>
														<div class="mt-2 d-flex flex-column">
															<v-chip label small class="caption mb-1">
																data creazione:<strong class="ml-2">{{ gStandardDate(item.DataCreazione) }}</strong>
															</v-chip>
															<v-chip label small class="caption mb-1">
																data ultima modifica:<strong class="ml-2">{{ gStandardDate(item.DataUltimaModifica) }}</strong>
															</v-chip>
															<v-chip label small class="caption">
																data ultimo accesso:<strong class="ml-2">{{ gStandardDate(item.DataUltimoAccesso) }}</strong>
															</v-chip>
														</div>
													</v-card-text>
												</v-card>
												<v-card style="background-color:rgba(0,0,255,0.025)" class="mt-1">
													<v-card-title>Note per l'utente "{{ item.FullName }}"</v-card-title>
													<v-card-text>
														<pre>{{ item.Notes ? item.Notes : '-' }}</pre>
													</v-card-text>
												</v-card>
											</div>
										</td>									
									</template>
								</v-data-table>
							</v-col>
						</v-row>
					</v-tab-item>
					<!-- tabGroups -->
					<v-tab-item key="tabGroups">
						<v-alert type="info" dense outlined text>
							Lavori in corso... <br>
							attenzione questi sono i gruppi per l'azienda: <strong>{{ currentUser.AziendaCorrente.Nome }}</strong>
						</v-alert>						
						<v-row>
							<v-col cols="12">
								<v-data-table 
									class="mt-6"
									:loading="loading"
									:items="groups" 
									:headers="groupsHeaders"
									:sort-by="['Ordinamento']" 
									:sort-desc="[false]"
									item-key="NomeGruppo"
									hide-default-footer
									disable-pagination
									disable-filtering
									show-expand
									:single-expand="false"
									@click:row="editGroup"
								>
									<template v-slot:item.NomeGruppo="{ item }">
										<standard-label cssclass="body-1 warning--text">
											{{ item.NomeGruppo }}
										</standard-label>
									</template>
									<template v-slot:item.DescrizioneGruppo="{ item }">
										<standard-label cssclass="caption">
											{{ item.DescrizioneGruppo }}
										</standard-label>
									</template>
									<template v-slot:item.UtentiCollegati="{ item }">
										<div class="text-right">
											{{ item.UtentiCollegati }}
										</div>
									</template>
									<template v-slot:item.PersoneCollegate="{ item }">
										<div class="text-right">
											{{ item.PersoneCollegate }}
										</div>
									</template>
									<template v-slot:item.Ordinamento="{ item }">
										<div class="text-right">
											{{ item.Ordinamento }}
										</div>
									</template>
									<template v-slot:item.Appartenenza="{ item }">
										<v-btn v-if="item.PersoneCollegate===0 && item.UtentiCollegati===0" right x-small color="warning" icon title="cancella gruppo" @click.stop="eliminaGruppo(item)">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</template>
									<template v-slot:expanded-item="{ headers, item }">
										<td :colspan="headers.length" class="tdExtended">
											<v-row>
												<v-col cols="12" lg="6">
													<v-card style="background-color:rgba(255,0,0,0.025)">
														<v-card-title>Utenti gruppo "{{ item.NomeGruppo }}"</v-card-title>
														<v-card-text>
															<table class="tblGruppi">
																<tbody v-for="utente in item.UtentiCollegatiList" :key="utente.Id">
																	<tr :class="utente.Visibile ? '' : 'invisibile'">
																		<td class="body-2">
																			{{ utente.FullName }}
																		</td>
																		<td class="caption">
																			{{ utente.Email }}
																		</td>
																	</tr>
																</tbody>
															</table>
														</v-card-text>
													</v-card>
												</v-col>
												<v-col cols="12" lg="6">
													<v-card style="background-color:rgba(0,255,0,0.025)">
														<v-card-title>Persone gruppo "{{ item.NomeGruppo }}"</v-card-title>
														<v-card-text>
															<table class="tblUtenti">
																<tbody v-for="persona in item.PersoneCollegateList" :key="persona.Id">
																	<tr :class="persona.Visibile ? '' : 'invisibile'">
																		<td class="body-2">
																			{{ persona.FullName }}
																		</td>
																		<td class="caption">
																			{{ persona.Email }}
																		</td>
																	</tr>
																</tbody>
															</table>
														</v-card-text>
													</v-card>
												</v-col>
											</v-row>
										</td>
									</template>
								</v-data-table>
								<!--
								<v-list two-line>
									<template v-for="item in groups">
										<v-list-item :key="item.NomeGruppo" @click="editGroup(item)">
											<v-list-item-content>
												<v-list-item-title>{{ item.NomeGruppo }}</v-list-item-title>
												<v-list-item-subtitle>{{ item.DescrizioneGruppo }}</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action>
												<v-list-item-action-text class="caption grey--text text-right">
													<standard-label icon="mdi-swap-vertical">order: <strong>{{ item.Ordinamento }}</strong></standard-label>
													<standard-label icon="mdi-account" v-if="item.UtentiCollegati>0">users: <strong>{{ item.UtentiCollegati }}</strong></standard-label>
													<v-btn v-else right x-small color="error" text title="cancella gruppo" @click.stop="eliminaGruppo(item)">elimina</v-btn>
												</v-list-item-action-text>
											</v-list-item-action>
										</v-list-item>
										<v-divider class="mt-0 mb-0" :key="item.NomeGruppo+'d'"></v-divider>
									</template>
								</v-list>
								-->
							</v-col>
						</v-row>
					</v-tab-item>
					<!-- tabGroups -->
					<v-tab-item key="tabUsersStatus">
						<v-alert type="info" dense outlined text>
							Lavori in corso... <br>
							attenzione questi sono tutti gli utenti loggati sul sistema, indipendentemente dall'azienda corrente.
						</v-alert>
						<v-simple-table>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">
											Utente
										</th>
										<th class="text-left">
											Ultimo login
										</th>
										<th class="text-left">
											Ultimo heartbeat
										</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in usersStatusFiltered" :key="item.Id">
										<td><strong>{{ item.FullName }}</strong> ({{ item.Email }})</td>
										<td>{{ gStandardDateWithHour(item.DataUltimoAccesso) }}</td>
										<td>{{ gStandardHour(item.DataUltimoPing) }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>

		<!-- debug -->
		<template v-slot:debug>
			<v-row>
				<v-col cols="12" md="6">
					<debug-panel label="users">
						{{ users }}
					</debug-panel>
				</v-col>
				<v-col cols="12" md="6">
					<debug-panel label="groups">
						{{ groups }}
					</debug-panel>
				</v-col>
			</v-row>
		</template>

		<!-- footer -->
		<template v-slot:footer>
			<v-row class="mt-1">
				<v-col cols="6" sm="2" order="2" order-sm="1">
					<v-btn small class="mt-3" icon title="Ricarica la lista" :disabled="loading" :loading="loading" @click="loadUsersAndGroups">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</template>

		<!-- inizio dialog utenti -->
		<v-dialog v-model="dialogUser" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogUser = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialogUser = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ selectedUser.New ? 'Nuovo' : 'Modifica' }} utente - Azienda: <strong>{{ currentUser.AziendaCorrente.Nome }}</strong></v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loading" :loading="loading" @click="updateUser">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
						<v-col cols="12" lg="12">
							<v-text-field v-model="selectedUser.Email" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="username (Email)" hint="Attenzione che deve essere univoco indipendentemente dall'azienda" />
						</v-col>
						<v-col v-if="selectedUser.New" cols="12" lg="12">
							<v-text-field v-model="selectedUser.NewPassword" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Password" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="selectedUser.Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Nome" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-text-field v-model="selectedUser.Cognome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Cognome" />
						</v-col>
						<v-col v-if="!selectedUser.IsCurrentUser" cols="12" lg="6">
							<v-select v-model="selectedUser.Stato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :items="statiUtente" label="Stato" />
						</v-col>
						<v-col v-if="!selectedUser.IsCurrentUser" cols="12" lg="6">
							<v-select v-model="selectedUser.Tipo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :items="tipiUtente" label="Profilo" />
						</v-col>
						<v-col cols="12" lg="6">
							<v-textarea v-model="selectedUser.Notes" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Note" rows="2" auto-grow />
						</v-col>
						<v-col cols="12" lg="6">
							<v-select v-model="selectedUser.Lingua" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :items="languagesTable" item-value="Id" item-text="Desc" label="Lingua" />
						</v-col>
					</v-row>
					<v-row>
						<v-col v-for="gruppo in selectedUser.Gruppi" :key="gruppo.NomeGruppo" cols="3">
							<v-checkbox v-model="gruppo.Appartenenza" dense :label="gruppo.NomeGruppo" :title="gruppo.DescrizioneGruppo" @change="updateGruppiAppartenenza" />
						</v-col>
					</v-row>
					<v-alert v-if="errorUser" type="error" dense text>
						{{ errorUser }}
					</v-alert>
					<debug-panel label="selectedUser">
						{{ selectedUser }}
					</debug-panel>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog utenti -->			

		<!-- inizio dialog gruppi -->
		<v-dialog v-model="dialogGroup" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialogGroup = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialogGroup = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentGroup.New ? 'Nuovo' : 'Modifica' }} gruppo - Azienda: <strong>{{ currentUser.AziendaCorrente.Nome }}</strong></v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :disabled="loading" :loading="loading" @click="updateGroup">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
						<v-col md="6">
							<v-text-field v-model="currentGroup.NomeGruppo" :disabled="!currentGroup.New" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Nome" hint="Identificativo univoco e immodificabile del NomeGruppo" />
						</v-col>
						<v-col md="6">
							<v-text-field v-model="currentGroup.DescrizioneGruppo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Descrizione" />
						</v-col>
						<v-col md="6">
							<v-text-field v-model="currentGroup.Ordinamento" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Ordinamento" hint="Mettendo 0 il gruppo verrà automaticamente spostato a fine lista" />
						</v-col>
					</v-row>
					<v-alert v-if="errorGroup" type="error" dense text>
						{{ errorGroup }}
					</v-alert>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog gruppi -->			

		<loading :loading="loading" />
	</standard-container>
</template>

<script>
import StandardContainer from '@/components/standard-container'
import DebugPanel from '@/components/debug-panel'
import StandardLabel from '@/components/standard-label'
import Loading from '@/components/loading'
import Avatar from 'vue-avatar'
import {Entropy} from 'entropy-string'
export default {
	name: 'AdminUsersPage',
	components: { StandardContainer, DebugPanel, StandardLabel, Loading, Avatar },
	data: () => ({
		currentTab:0,
		fabVisible: true,
		dialogGroup: false,
		dialogUser: false,
		errorGroup: '',
		errorUser: '',
		loading: false,
		search: '',
		visualizzaSospesi: true,
		groupsHeaders: [
			{ text: 'Nome', value: 'NomeGruppo', width:200 },
			{ text: 'Descrizione', value: 'DescrizioneGruppo'},
			{ text: '# Utenti', value: 'UtentiCollegati', width:90 },
			{ text: '# Persone', value: 'PersoneCollegate', width:90 },
			{ text: 'Ordinamento', value: 'Ordinamento', width:90 },
			{ text: '', value: 'Appartenenza', width:30 }, // colonna farlocca usata per il delete
		],
		usersHeaders: [
			{ text: '', value: 'Id', width:50},
			{ text: 'Nome', value: 'FullName'},
			{ text: 'Stato', value: 'Stato'},
			{ text: 'Tipo', value: 'Tipo'},
			{ text: 'Lingua', value: 'Lingua'},
			{ text: 'Gruppi', value: 'GruppiAppartenenza'},
			{ text: '', value: 'Email'}, // colonna farlocca usata per il delete e altro
		],
		statiUtente: [{text:'Sospeso', value:0, color:'warning'}, {text:'Attivo', value:1, color:''}],
		tipiUtente: [
			{text:'User', value:0, color:'info'}, 
			{text:'Super User', value:1, color:'success'}, 
			{text:'Administrator', value:2, color:'error'},
			{text:'Training Manager', value:10, color:'warning'}

		],
		users: [],
		selectedUser: {},
		groups: [],
		currentGroup: {},
	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		decodifiche() { return this.$store.getters['decodifiche'] },
		aziende() { return this.$store.getters['aziende'] },
		languagesTable() { return this.$store.getters['translation/languagesTable'] },
		currentUser() { return this.$store.getters['currentUser'] },
		usersStatus() { return this.$store.getters['usersStatus'] },
		usersStatusFiltered() { 
			return this.usersStatus.filter(user => { return user.IsConnected }) 
		},
		usersFiltered() {
			return this.users.filter(item => {
				return this.visualizzaSospesi || item.Stato !== 0
			})
		}
	},
	watch:{
		currentTab(to, from) {
			// giochino sporco per far capire all'utente che il fab (che non si sposta) è realativo alla tab corrente e non globale
			// quindi lo nascondiamo giusto il tempo di fare lo spostamento di tab
			if(to!==from) {
				this.fabVisible = false
				setTimeout(()=>{ this.fabVisible = true },500)
			}
		}
	},
	mounted() {
		this.loadUsersAndGroups()
	},
	methods: {
		// carica i dati dallo store (disacoppiandoli) e poi cicla la tabella risultante per caricare le decodifiche necessarie
		initData() {
		},
		loadUsersAndGroups() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/UsersAndGroups/List'}).then(data => {
				this.users = data.Users
				this.groups = data.Groups
				this.loading = false
			}).catch(error => {
				console.error(error)
				this.loading = false
			})
		},
		eliminaGruppo(item) {
			this.$swal('ATTENZIONE!', 'Sei sicuro di voler eliminare il gruppo "'+item.NomeGruppo+'" ?', 'warning', { buttons: { ok: 'Si', cancel: 'No' }}).then(result => {
				if (result === 'ok') {
					this.loading = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/UsersAndGroups/Group/Delete', value: item}).then(data => {
						this.users = data.Users
						this.groups = data.Groups
						this.loading = false
					}).catch(error => {
						console.error(error)
						this.loading = false
					})
				}
			})
		},
		aggiungiGruppo() {
			var newGroup = {
				NomeGruppo: '',
				DescrizioneGruppo: '',
				Ordinamento: 0,
				UtentiCollegati: 0,
				New: true
			}
			this.editGroup(newGroup)
		},
		editGroup(item) {
			this.$set(this, 'currentGroup', JSON.parse(JSON.stringify(item)))
			this.dialogGroup = true
		},
		updateGroup() {
			this.loading = true
			this.errorGroup = ''
			this.$store.dispatch('genericApiPost', {apiUrl: '/UsersAndGroups/Group/Save', value: this.currentGroup}).then(data => {
				this.users = data.Users
				this.groups = data.Groups
				this.loading = false
				this.dialogGroup = false
				this.$store.dispatch('updateGruppi', data.Groups) // aggiorna anche la struttura dei gruppi per le persone
			}).catch(error => {
				console.error(error)
				this.errorGroup = error
				this.loading = false
			})
		},
		// -----------------------------------------------------
		async aggiungiUtente() {
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			const entropy = new Entropy()
			var newUtente = {
				Id: negativeId,
				Stato: 1,
				Tipo: 0,
				Nome: '',
				Cognome: '',
				Email: '',
				IsCurrentUser: false,
				New: true,
				GruppiAppartenenza: [],
				NewPassword: entropy.string() // suggerisce pwd sicura
			}
			this.editUser(newUtente)
		},
		eliminaUtente(item) {
			this.$swal('ATTENZIONE!', 'Sei sicuro di voler eliminare l\'utente "'+item.FullName+'" ?', 'warning', { buttons: { ok: 'Si', cancel: 'No' }}).then(result => {
				if (result === 'ok') {
					this.loading = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/UsersAndGroups/User/Delete', value: item}).then(data => {
						this.users = data.Users
						this.groups = data.Groups
						this.loading = false
					}).catch(error => {
						console.error(error)
						this.loading = false
					})
				}
			})
		},
		resetPasswordUtente(item) {
			this.$swal({
				title: 'Reset password',
				text: 'Digita la nuova password per l\'utente "'+item.FullName+'":',
				icon: 'warning',
				content: 'input',
			}).then((result) => {
				// console.warn('resetPasswordUtente', result)
				if (result) {
					item.NewPassword = result
					this.loading = true
					this.$store.dispatch('genericApiPost', {apiUrl: '/UsersAndGroups/User/Reset', value: item}).then(data => {
						this.users = data.Users
						this.groups = data.Groups
						this.loading = false
					}).catch(error => {
						console.error(error)
						this.loading = false
					})
				}
			})
		},
		editUser(item) {
			this.$set(this, 'selectedUser', JSON.parse(JSON.stringify(item)))
			// aggiunge un campo Gruppi allo user (ricordo che con la #836 questo non arriva più dalla api) e lo valorizza con una copia della struttura dei gruppi
			this.$set(this.selectedUser, 'Gruppi', JSON.parse(JSON.stringify(this.groups)))
			// valorizza il flag "Appartenenza" del campo appena aggiunto identificando quelli il cui nome compare in GruppiAppartenenza
			this.selectedUser.Gruppi.forEach(item => {
				var dummy = this.selectedUser.GruppiAppartenenza ? this.selectedUser.GruppiAppartenenza.find(subitem => { return subitem === item.NomeGruppo}) : null
				item.Appartenenza = !!dummy
				// gia che ci siamo puliamo dagli elementi extra che servono solo per le vosualizzazioni avanzate
				item.UtentiCollegatiList = []
				item.PersoneCollegateList = []				
			})
			//
			this.dialogUser = true
		},
		// aggiorna la GruppiAppartenenza al variare della gruppi (ricordo che dalla #836 la Gruppi non arriva e non viene inviata alle api ma solo ricorstruita al volo in locale)
		updateGruppiAppartenenza() {
			this.selectedUser.GruppiAppartenenza = this.selectedUser.Gruppi.filter( gruppo => { return gruppo.Appartenenza }).map( gruppo => { return gruppo.NomeGruppo })
		},		
		updateUser() {
			this.loading = true
			this.errorUser = ''
			this.$store.dispatch('genericApiPost', {apiUrl: '/UsersAndGroups/User/Save', value: this.selectedUser}).then(data => {
				this.users = data.Users
				this.groups = data.Groups
				this.loading = false
				this.dialogUser = false
			}).catch(error => {
				console.error(error)
				this.errorUser = error
				this.loading = false
			})
		},
		// decodifica a video la descrizione di un gruppo
		decodificaDescrizioneGruppo(nomeGruppo) {
			var gruppo = this.groups.find( x => { return x.NomeGruppo === nomeGruppo})
			return gruppo ? gruppo.DescrizioneGruppo : '-'
		},
		// decodifica nome azienda
		decodificaNomeAzienda(idAzienda) {
			var azienda = this.aziende.find( x => { return x.Id === idAzienda})
			return azienda ? azienda.Nome : '☠'
		},
	}
}
</script>


<style scoped lang="less">
.tdExtended {

	.tblUtenti, .tblGruppi {
		td {
			padding:0;
			height: auto;
		}
		tr.invisibile {
			td {
				opacity: 0.5;
				text-decoration: line-through;
			}
		}
	}
}
.monospaced {
	font-family: monospace;
}
.opaque {
	opacity: 0.5 !important;	
}
</style>